import React, { useEffect, useState } from "react";
import { FaSearch, FaFilter, FaSort, FaBars, FaTimes, FaEdit } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import "./CommonTable.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { GrFormNext } from "react-icons/gr";
import usePrivilege from "../Privileges/Privileges";
import { useSelector } from "react-redux";
import MetaHelmet from "../Helmet/Helmet";
import { useRef } from "react";
import {
  useGetFilterColumnsQuery,
  useUpdateFilterColumnsMutation,
} from "../../Redux/API/atsSlice";
import EmptyData from "../EmptyData/EmptyData";
import { FaRegEdit,FaTrashAlt } from "react-icons/fa";
import closeViewIcon from '../../Assests/close_view_icon.svg';
import openViewIcon from '../../Assests/open_view_icon.svg';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import e1 from '../../Assests/e1.svg';
import e2 from '../../Assests/e2.svg';
import d1 from '../../Assests/d1.svg';
import d2 from '../../Assests/d2.svg';
import d3 from '../../Assests/d3.svg';
import delete4 from '../../Assests/delete4.svg';
import edit3 from '../../Assests/edit3.svg'


const CommonTable = ({
  setParseFile,
  setSelectInterview,
  module_name,
  meta_title,
  detailNavigate,
  columns,
  data,
  onRowAction,
  title,
  headerButton,
  headerIcon,
  headerNavigateTo,
  sidebarLabels,
  rowIdentifier = "id",
  privilege,
  onFilterChange,
  onSortChange,
  selectedColumns,
  onColumnChange,
  showSidebar = true,
  showAction = true,
  showSearchBarContainer = true,
  showFilterTitle = true,
  showPagination = true,
  showFilterAndSortBtn = true,
  showInsideSearchBar =false,
  showInsideHeaderButton =false,


}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showCandidatePopup, setShowCandidatePopup] = useState(false);
  const [showPopupResume, setShowPopupResume] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
  const [jobsPerPage, setJobsPerPage] = useState(10);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  console.log("data", selectedColumns);
  const isAccessible = usePrivilege(privilege);

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  const filteredData = data.filter((row) =>
    columns.some((col) =>
      String(row[col.key])?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const currentData = filteredData.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.ceil(filteredData.length / jobsPerPage);

  const handleDropdownToggle = (rowId) => {
    setOpenDropdown(openDropdown === rowId ? null : rowId);
  };

  const isAuth = useSelector((state) => state.auth);
  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  const goToPrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  // const handleButtonClick = () => {
  //   if (headerNavigateTo) {
  //     navigate(headerNavigateTo);
  //   }

  // };

  const handleButtonClick = () => {
    if (module_name === "candidates") {
      setShowCandidatePopup((prev) => !prev);
    } else if (headerNavigateTo) {
      navigate(headerNavigateTo);
    }
  };

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const [files,setfiles] = useState("")
  const [selectedFilter, setSelectedFilter] = useState("All Jobs");

  useEffect(() => {
    if (title?.length > 0) {
      setSelectedFilter(title);
    }
  }, [title]);
  const [sortOrder, setSortOrder] = useState("Newest First");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    onFilterChange(filter); // Notify parent component
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
    onSortChange(order); // ✅ Notify JobOpening.js
  };

  // const handleColumnSelection = (columnKey) => {
  //   const newColumns = selectedColumns.includes(columnKey)
  //     ? selectedColumns.filter((col) => col !== columnKey)
  //     : [...selectedColumns, columnKey];

  //   onColumnChange(newColumns);
  // };

  const {
    data: getFilterColumn,
    refetch,
    isSuccess,
  } = useGetFilterColumnsQuery({
    domain_name: isAuth?.user?.domain_name,
    module_name: module_name || "",
  });
  console.log("columns", getFilterColumn);
  const [filterColumn, setFilterColums] = useState([]);
  useEffect(() => {
    if (getFilterColumn?.status) {
      setFilterColums(getFilterColumn?.message);
      onColumnChange(
        getFilterColumn?.message
          ?.filter((item, i) => parseInt(item?.is_enabled) === 1)
          ?.map((item, i) => item?.column_name)
      );
    }
  }, [module_name, isSuccess]);

  const handleColumnSelection = (columnKey) => {
    const newColumns = selectedColumns.includes(columnKey)
      ? selectedColumns.filter((col) => col !== columnKey)
      : [...selectedColumns, columnKey];
    setFilterColums((prev) => {
      return prev.map((item) => {
        if (item?.column_name === columnKey) {
          return {
            ...item,
            is_enabled: parseInt(item?.is_enabled) === 1 ? 0 : 1,
          };
        }
        return { ...item };
      });
    });
    // onColumnChange(filterColumn.map((item,i)=>item?.col))
    onColumnChange(newColumns);
  };
  const [update] = useUpdateFilterColumnsMutation();
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await update({
        domain_name: isAuth?.user?.domain_name,
        data: filterColumn,
      });
      console.log("res", res);
      if (res?.data?.status) {
        refetch();
      }
    } catch (err) {}
  };

  // Create a ref to monitor clicks outside the dropdown
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null); // Close the dropdown when clicking
      }
    };

    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
    <MetaHelmet title={meta_title} />
    {showSearchBarContainer && (
      <div
        className="commonTable-search-container"
        style={
          params.get("type") === "candidates" && params.get("id")
            ? { margin: "0.5rem 0" }
            : {}
        }
      >
        <div
          className="commonTable-search-box"
          style={
            params.get("type") === "candidates" && params.get("id")
              ? { marginLeft: "0" }
              : {}
          }
        >
          <input
            type="text"
            placeholder="Search by Job Id, Role, Title..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FaSearch className="commonTable-search-icon" />
        </div>
  
        {module_name === "candidates" ? (
          <div
            ref={dropdownRef}
            className="commonTable-dropdown-container"
            onClick={() => setShowCandidatePopup(!showCandidatePopup)}
            style={
              isAccessible.includes(isAuth?.user?.user_id)
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <button className="commonTable-create-btn-header">
              {headerButton} {headerIcon}
            </button>
  
            {showCandidatePopup && (
              <div className="common-table-candidate-popup">
                <button
                  onClick={() => {
                    navigate("/ats/dashboard/?type=createCandidates");
                    setShowCandidatePopup(false);
                  }}
                >
                  Add Manually
                </button>
                <button
                  onClick={() => {
                    setShowCandidatePopup(false);
                    setShowPopupResume(true);
                  }}
                >
                  Upload Resume
                </button>
              </div>
            )}
  
            {showPopupResume && (
              <div className="candidate-resume-popup">
                <div className="candidate-popup-content">
                  <h3>Upload Your Resume</h3>
                  <input
                    type="file"
                    onChange={(e) => {
                      setfiles(e.target.files[0]);
                      setParseFile(e.target.files[0]);
                      setShowPopupResume(false);
                    }}
                  />
                  <button onClick={() => setShowPopupResume(false)}>Close</button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="commonTable-dropdown-container">
            <button
              className="commonTable-create-btn-header"
              style={
                isAccessible.includes(isAuth?.user?.user_id)
                  ? { display: "block" }
                  : { display: "none" }
              }
              onClick={handleButtonClick}
            >
              {headerButton} {headerIcon}
            </button>
          </div>
        )}
      </div>
    )}
  
    <div
      className="commonTable-container"
      style={
        module_name === "documents" ||
        (params.get("type") === "candidates" && params.get("id"))
          ? { width: "100%" }
          : { width: "96%" }
      }
    >
      <div
        className="commonTable-header"
        style={module_name === "documents" ? { display: "none" } : {}}
      >
        <div className="commonTable-title">
          {showSidebar && (
            <button
              className="commonTable-filter-btn"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              {isSidebarOpen ? (
                <img src={closeViewIcon} />
              ) : (
                <img src={openViewIcon} style={{ padding: "10px 0px" }} />
              )}
            </button>
          )}
  
          {showFilterTitle && (
            <p className="commontable-filter-currentdata">{title}</p>
          )}
  
          {showInsideSearchBar && (
            <div
              className="commonTable-search-box"
              style={
                params.get("type") === "candidates" && params.get("id")
                  ? { marginLeft: "0" }
                  : {}
              }
            >
              <input
                style={{ fontSize: "13px" }}
                type="text"
                placeholder="Search by Job Id, Role, Title..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="commonTable-search-icon" />
            </div>
          )}
        </div>
  
        {showFilterAndSortBtn && (
          <div className="commonTable-controls">
            {showInsideHeaderButton && params.get("type") !== "home" && (
              <button
                className="commonTable-create-btn-header"
                style={
                  isAccessible.includes(isAuth?.user?.user_id)
                    ? { display: "block" }
                    : { display: "none" }
                }
                onClick={handleButtonClick}
              >
                <span>{headerButton}</span>{" "}
                <span className="common-table-header-icon">{headerIcon}</span>
              </button>
            )}
  
            <button
              className="commonTable-filter-column-btn"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <FaFilter /> Filter
            </button>
  
            <button
              className="commonTable-sort-by-btn"
              onClick={() => setIsSortMenuOpen(!isSortMenuOpen)}
            >
              <FaSort /> Sort by
            </button>
          </div>
        )}
      </div>
  
      <div className="commonTable-wrapper">
        {showSidebar && (
          <div className={`commonTable-sidebar ${isSidebarOpen ? "show" : ""}`}>
            <div className="commonTable-filter-options">
              {sidebarLabels.map((label, index) => (
                <label key={index} onClick={() => handleFilterChange(label)}>
                  <input
                    type="radio"
                    name="jobFilter"
                    checked={selectedFilter === label}
                    readOnly
                  />{" "}
                  {label}
                </label>
              ))}
            </div>
          </div>
        )}
  
        <div className="commonTable-scrollable-table">
          <table>
            <thead>
              <tr>
                {columns.map((col) =>
                  selectedColumns.includes(col.key) ? (
                    <th key={col.key}>{col.label}</th>
                  ) : null
                )}
                {showAction && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td
                    colSpan={selectedColumns.length + (showAction ? 1 : 0)}
                    style={{ textAlign: "center", padding: "2rem" }}
                  >
                    <EmptyData />
                  </td>
                </tr>
              ) : (
                currentData.map((row, index) => (
                  <tr key={index}>
                    {columns.map((col, indx) =>
                      selectedColumns.includes(col.key) ? (
                        indx === 0 || indx === 1 ? (
                          <td
                            key={col.key}
                            style={{ cursor: "pointer", color: "#007bff" }}
                            className="commontable-hyper-link"
                            onClick={() => {
                              if (detailNavigate?.includes("candidates")) {
                                navigate(
                                  `${detailNavigate}&id=${
                                    row[Object.keys(row)[0]]
                                  }&idx=${index}`
                                );
                              } else if (module_name === "interviews") {
                                setSelectInterview(row[Object.keys(row)[0]]);
                              } else {
                                navigate(
                                  `${detailNavigate}&id=${row[Object.keys(row)[0]]}`
                                );
                              }
                            }}
                          >
                            {row[col.key]}
                          </td>
                        ) : (
                          <td key={col.key}>{row[col.key]}</td>
                        )
                      ) : null
                    )}
                    {showAction && (
                      <td style={{ position: "relative" }}>
                        <button
                          onClick={() =>
                            handleDropdownToggle(row[rowIdentifier])
                          }
                          style={{ background: "transparent" }}
                        >
                          <BsThreeDots />
                        </button>
                        {openDropdown === row[rowIdentifier] && (
                          <div
                            className="commonTable-action-dropdown"
                            ref={dropdownRef}
                          >
                            {onRowAction(row, index)}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
  
      {/* Filter Menu */}
      {isFilterOpen && (
        <div className="common-table-bg-dull">
          <div
            className="commonTable-filter-menu-popup open"
            style={
              params.get("type") === "home" ? { top: "40%" } : { top: "18%" }
            }
          >
            <div className="commonTable-filter-menu-header">
              <h3>Filter Column</h3>
              <button
                className="commonTable-filter-close-btn"
                onClick={() => setIsFilterOpen(false)}
              >
                <FaTimes />
              </button>
            </div>
  
            <div className="commonTable-filter-options">
              {columns.map((col) => (
                <label key={col.key}>
                  <input
                    type="checkbox"
                    checked={
                      parseInt(
                        filterColumn?.find(
                          (item) => item?.column_name === col?.key
                        )?.is_enabled
                      ) === 1
                    }
                    onChange={() => handleColumnSelection(col.key)}
                  />
                  {col.label}
                </label>
              ))}
            </div>
  
            <div className="commonTable-filter-actions">
              <button
                className="commonTable-cancel-btn"
                onClick={() => setIsFilterOpen(false)}
              >
                Cancel
              </button>
              <button
                onClick={(e) => {
                  handleUpdate(e);
                  setIsFilterOpen(false);
                }}
                className="commonTable-save-btn"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
  
      {/* Sort Menu */}
      {isSortMenuOpen && (
        <div
          className="commonTable-sortby-menu"
          style={params.get("type") === "home" ? { top: "83%" } : {}}
        >
          <h4>Sort By</h4>
          <button
            onClick={() => handleSortChange("Newest First")}
            className={sortOrder === "Newest First" ? "active" : ""}
          >
            Newest First
          </button>
          <button
            onClick={() => handleSortChange("Oldest First")}
            className={sortOrder === "Oldest First" ? "active" : ""}
          >
            Oldest First
          </button>
        </div>
      )}
    </div>
  
    {/* Pagination */}
    {showPagination && (
      <div className="pagination-controls">
        <span>Go To Page</span>
        <input
          type="number"
          value={currentPage}
          onChange={(e) => goToPage(Number(e.target.value))}
          min="1"
          max={totalPages}
        />
        <select
          value={jobsPerPage}
          onChange={(e) => setJobsPerPage(Number(e.target.value))}
        >
          <option value="10">10 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
        </select>
        <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
          <GrFormPrevious />
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => goToPage(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages || totalPages === 0}
        >
          <GrFormNext />
        </button>
      </div>
    )}
  </>
  
  );
};

export default CommonTable;
