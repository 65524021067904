import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "../LandingPage";
import AdminLogin from "../Admin/Auth/AdminLogin";
import SignUp from "../User/SignUp/SignUp";
import Login from "../User/Login/Login";

import DashBoard from "../Components/DashBoard/DashBoard";
import ProtectedRoute from "./ProtectedRoute";
import AtsHomePage from "../Components/AtsHomePage/AtsHomePage";
import EditDepartments from '../Components/Departments/EditDepartments'
// import EditJob from "../EditJobs/EditJob";
import EditJob from '../Components/EditJobs/EditJob'
import CandidateDetail from "../Components/Candidates/CandidateDetail";
import EditCandidate from "../Components/CreateCandidate/EditCandidate";
import EditInterview from "../Components/Interviews/EditInterview";
import CommonTable from "../Components/CommonTable/CommonTable";
import usePrivilege from "../Components/Privileges/Privileges";
import { useSelector } from "react-redux";
// import ForgotPassword from "../User/ForgotPassword/ForgotPassword";
import ForgotPassword from "../User/ForgotPassword/ForgotPassword";
import Interview from "../Components/Interviews/Interview";
import AtsDashBoardHeader from "../Components/AtsDashBoardHeader/AtsDashBoardHeader";

const RouteComponent = () => {
  const isJobUpdate = usePrivilege('Job Update')
  const isDeptUpdate = usePrivilege('Department Update')
  const isCandidateUpdate = usePrivilege('Candidate Update')
  const isInterviewUpdate = usePrivilege('Interview Update')
   const isAuth = useSelector((state) => state?.auth);
  return (
    <Router>
        <Routes>
        <Route path="/" element={<AtsHomePage/>}/>
        <Route path='/table' element={<CommonTable/>}/>
          {/* <Route path="/" element={<LandingPage/>} /> */}
          <Route path="/Adminlogin" element={<AdminLogin/>} />
          <Route path="/signup" element={<SignUp/>}/> 
          <Route path="/login" element={<Login/>}/>
          <Route path='/forgotPassword' element={<ForgotPassword/>}/>

          {
            isAuth && isJobUpdate.includes(isAuth?.user?.user_id) &&
            <Route path="/ats/dashboard/EditJob/:id" element={<EditJob/>}/>
          }
          {
             isAuth && isDeptUpdate.includes(isAuth?.user?.user_id) &&
             <Route path="/ats/dashboard/EditDepartment/:dept_id" element={<EditDepartments/>}/>
          }

          {
            isAuth && isCandidateUpdate.includes(isAuth?.user?.user_id) &&
            <Route path="/ats/dashboard/EditCandidate/:candidate_id" element={<EditCandidate/>}/>
          }
          {
            isAuth && isInterviewUpdate.includes(isAuth?.user?.user_id) &&
            <Route path="/ats/dashboard/EditInterview/:interview_id" element={
              <>
              <AtsDashBoardHeader/>
            <div style={{marginTop:'20vh'}}>
            <Interview isEdit={true}/>
            </div>
            </>
          }/>
          }
        
          {/* <Route path="/ats/dashboard" element={<CandidateDetail />} /> */}
          <Route path="/ats/dashboard" element={
            <ProtectedRoute>
              <DashBoard/>
            </ProtectedRoute>
            
            
          }/>
        </Routes>
    </Router>
  );
};

export default RouteComponent;
