import React, { useEffect, useRef, useState } from 'react';
import "./CandidateDocument.css";
import { FaTimes } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { useGetCandidateDocumentsQuery, usePostUploadDocumentMutation } from '../../../../Redux/API/atsSlice';
import { useSearchParams } from 'react-router-dom';
import view from '../../../../Assests/CandidateDetailImages/view.svg';
import share from '../../../../Assests/CandidateDetailImages/share.svg';
import edit from '../../../../Assests/CandidateDetailImages/edit.svg';
import deleteIcon from '../../../../Assests/CandidateDetailImages/delete.svg';
import download from '../../../../Assests/CandidateDetailImages/Download.svg';
import CommonTable from '../../../CommonTable/CommonTable';
import cardicon from "../../../../Assests/CandidateDetailImages/CardIcon.svg"
import coloredCard from '../../../../Assests/CandidateDetailImages/CardColored.svg'
import listicon from '../../../../Assests/CandidateDetailImages/ListIcon.svg'
import coloredlist from '../../../../Assests/CandidateDetailImages/ListColored.svg';
import { CiSearch } from "react-icons/ci";
import {BASE_URL} from '../../../../Redux/API/apiSlice';
import { Link } from "react-router-dom";



const CandidateDocument = () => {
    const [addDocument, setAddDocument] = useState(false);
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [documentData, setDocumentData] = useState([])
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isCardView, setIsCardView] = useState(false);
    const [isTouched, setIsTouched] = useState({})

    const [formData, setFormData] = useState({
        documentName: '',
        documentType: '',
        status: '',
        expiryDate: '',
    });

    const handleOnBlur = (e)=>{
        setIsTouched((prev)=>{return{...prev,[e.target.name]:true}})
    }


    const fileInputRef = useRef(null);
    const isAuth = useSelector((state) => state.auth);
    const [params] = useSearchParams();
    const candidateid = params.get("id");
    const [addDocumentData] = usePostUploadDocumentMutation();
    const { data, isSuccess, refetch } = useGetCandidateDocumentsQuery({
        domain_name: isAuth?.user?.domain_name,
        candidate_id: candidateid
    })
    const [selectedColumns, setSelectedColumns] = useState([
        "document_name", "document_type", "status", "expiry_date"
    ]);


    useEffect(() => {
        if (data?.status) {

            console.log("document data", data?.message)
            setDocumentData(data?.message)
        }
    }, [data, isSuccess])
    const handleAddDocumentClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadedFile(file);
            setAddDocument(true);
        }
    };

    const handleCancel = () => {
        setAddDocument(false);
        setUploadedFile(null);
        setFormData({
            documentName: '',
            documentType: '',
            status: '',
            expiryDate: '',
        });
        setIsTouched({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSave = async () => {
        const { documentName, documentType, status, expiryDate } = formData;
        if (!uploadedFile || !documentName || !documentType || !status || !expiryDate) {
            alert("Please fill all fields and select a file.");
            return;
        }

        try {
            const base64File = await convertFileToBase64(uploadedFile);

            const payload = {
                domain_name: isAuth?.user?.domain_name,
                candidateid: candidateid,
                data: [
                    {
                        document_name: documentName,
                        document_type: documentType,
                        document_url: base64File,
                        expiry_date: expiryDate,
                        uploaded_by: isAuth?.user?.user_id,
                        status: status === "Active" ? "1" : "0"
                    }
                ]
            };

            const res = await addDocumentData(payload);
            console.log("Upload Response:", res);
            setIsTouched({});
            handleCancel();
        } catch (err) {
            console.error("Error uploading document:", err);
        }
    };

    const showError = (field) =>
        (isTouched[field]) && !formData[field];

    const handleRowAction = (job) => (


        <>

            {/* <button 
        onClick={() => navigate(`?type=job_openings&id=${job.job_id}`)}
        className="commonTable-dropdown-item"
      >
        <IoDocumentTextOutline /> View Job Detail
      </button> */}
            <button
                // onClick={()=>navigate(`EditJob/${job.job_id}`)}
                className="commonTable-dropdown-item"
            >
                {/* <FaRegEdit />  */}
                Edit Document
            </button>
            {/* <button 
        onClick={() => console.log("Delete job:", job.job_id)}
        className="commonTable-dropdown-item danger"
      >
        <RiDeleteBin5Line/> Delete Job
      </button> */}
        </>
    );

    const columns = [
        { key: "document_name", label: "Document Name" },
        { key: "document_type", label: "Document Type" },
        { key: "status", label: "Status" },
        { key: "expiry_date", label: "Expiry Date" },
    ];

    const handleViewDocument = (url) => {
        if (url) {
          window.open(`${BASE_URL}${url}`, '_blank');
        }
      };

    console.log("document", documentData)
    return (
        <div>
            <div className='cd-search-container'>


                <input type="text" className='cd-search-box' placeholder="Search by Name" />

                <div className='cd-buttons-container'>
                    <button onClick={handleAddDocumentClick} className='cd-search-addDocument-btn'>
                        Add Document
                    </button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />

                    {/* Toggle View Icon */}
                    {!isCardView ? (
                        <img
                            src={hoveredIcon === "list" ? coloredlist : listicon}
                            onClick={() => setIsCardView(true)}
                            onMouseEnter={() => setHoveredIcon("list")}
                            onMouseLeave={() => setHoveredIcon(null)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            alt="Switch to Card View"
                        />
                    ) : (
                        <img
                            src={hoveredIcon === "card" ? coloredCard : cardicon}
                            onClick={() => setIsCardView(false)}
                            onMouseEnter={() => setHoveredIcon("card")}
                            onMouseLeave={() => setHoveredIcon(null)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            alt="Switch to List View"
                        />
                    )}
                </div>
            </div>


            {isCardView ? (
                <div className="cd-card-container">
                    {documentData.map((doc, index) => (
                        <div key={index} className="cd-card">
                            <div className="cd-card-header">
                                <span className="cd-card-title">{doc.document_type}</span>
                                <span className={`cd-card-status ${doc.status === 1 ? 'active' : 'inactive'}`}>
                                    {doc.status === 1 ? 'Active' : 'Inactive'}
                                </span>
                            </div>
                            <div className="cd-card-body">
                                <p className='content'>{doc.document_name}</p>
                                <div className='expiry-date-container'>
                                    <p className='candidate-document-expirydate'><span className='candidate-document-expirydate-label'>Expiry Date:</span> {doc.expiry_date ? new Date(doc.expiry_date).toLocaleDateString() : 'N/A'}</p>
                                    <div className="cd-card-actions">
                                    <Link to={`${BASE_URL}${doc.document_url}`} target="_blank" download><img src={download} alt='download' /></Link>
                                        
                                        <img
                                            src={view}
                                            alt="view"
                                            onClick={() => handleViewDocument(doc.document_url)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <img src={edit} alt='edit' />
                                        <img src={deleteIcon} alt='delete' />
                                        <img src={share} alt='share' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <CommonTable
                    module_name={"documents"}
                    data={documentData}
                    columns={columns}
                    onRowAction={handleRowAction}
                    showSidebar={false}
                    showFilterAndSortBtn={false}
                    showPagination={false}
                    showFilterTitle={false}
                    showSearchBarContainer={false}
                    selectedColumns={selectedColumns}
                    onColumnChange={setSelectedColumns}
                />
            )}

            {addDocument && (
                <div className='cd-popup-overlay'>
                <div className='canidate-adddocument-popup open'>
                    <div className='canidate-adddocument-popup-header'>
                        <h3>Add Document</h3>
                        <button className='canidate-adddocument-popup-close-btn' onClick={handleCancel}>
                            <FaTimes />
                        </button>
                    </div>

                    <div className='candidate-adddocument-popup-content'>
                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Document Name <span style={{color: "red"}}>*</span></label>
                            <input
                                className={showError(formData?.documentName)?"invalid":'candidate-adddocument-popup-input'}
                                type='text'
                                name='documentName'
                                value={formData?.documentName}
                                onChange={handleChange}
                                onBlur={handleOnBlur}
                            />
                            {isTouched.documentName && !formData.documentName &&
                                <span className='error-data'>This field is required</span>
                            }
                        </div>

                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Document Type <span style={{color: "red"}}>*</span></label>
                            <select
                                className={showError(formData?.documentType)?"invalid":'candidate-adddocument-popup-input'}
                                name='documentType'
                                value={formData?.documentType}
                                onChange={handleChange}
                                onBlur={handleOnBlur}
                            >
                                <option value="">Select </option>
                                <option value="aadhar">Aadhar</option>
                                <option value="pan">PAN</option>
                                <option value="cover">Cover Letter</option>
                                <option value="offer">Offer Letter</option>
                            </select>
                            {isTouched.documentType && !formData.documentType &&
                                <span className='error-data'>This field is required</span>
                            }
                        </div>

                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Status <span style={{color: "red"}}>*</span></label>
                            <select
                                className={showError(formData?.status)?"invalid":'candidate-adddocument-popup-input'}
                                name='status'
                                value={formData?.status}
                                onChange={handleChange}
                                onBlur={handleOnBlur}
                            >
                                <option value="">Select</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                            {isTouched.status && !formData.status &&
                                <span className='error-data'>This field is required</span>
                            }
                        </div>

                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Expire Date</label>
                            <input
                                className='candidate-adddocument-popup-input'
                                type='date'
                                name='expiryDate'
                                value={formData.expiryDate}
                                onChange={handleChange}
                            />
                        </div>

                        {uploadedFile && (
                            <div className="candidate-adddocument-popup-inputcontainer">
                                <label className='candidate-adddocument-popup-label'>Uploaded Document</label>
                                <div className="uploaded-file-name">
                                    <p>{uploadedFile.name}</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="candidate-adddocument-popup-fixedcontainer">
                        <div className="ci-popup-line"></div>
                        <div className="candidate-adddocument-popup-bottomCon">
                            <button className="adddocument-popup-cancelbtn" onClick={handleCancel}>Cancel</button>
                            <button className="adddocument-popup-savebtn" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
                </div>
            )}
        </div>


    );
};

export default CandidateDocument;