import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CreateUser.css";
import {
  useCreateUserMutation,
  useGetHierachyQuery,
  useGetRoleQuery,
  useGetUsersQuery,
} from "../../../../Redux/API/atsSlice";
import { useGetCountryListQuery } from "../../../../Redux/API/homeSlice";
import { FaSearch, FaUserEdit } from "react-icons/fa";
import { showToast } from "../../../ToastContainer/ToastProvider";
import * as Yup from "yup";
const CreateUser = () => {
  const [iscreateUser, setisCreateUser] = useState(false);
  const [searchSettings, setSearchSettings] = useState("");
  const [SettingShowForm, setSettingShowForm] = useState(false);
  const isAuth = useSelector((state) => state.auth);
  const [form, setForm] = useState({
    domain_name: "",
    firstname: "",
    lastname: "",
    email_id: "",
    password: "",
    created_by: "",
    role_name: "",
    role_id: "",
    reportingManager: [],
    dottedLineManager: [],
    phonecode: "",
    mobile_number: "",
  });

  const { data: hierachy, isSuccess: hierachySuccess } = useGetHierachyQuery({
    domain_name: isAuth?.user?.domain_name,
  });
  const [createUser] = useCreateUserMutation();
  const [formErrors, setFormErrors] = useState({});

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email_id: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Min 6 characters")
      .required("Minimum 6 characters required"),
    phonecode: Yup.string().required("Code required"),
    mobile_number: Yup.string().required("Mobile number required"),
    role_name: Yup.string().required("Role is required"),
  });

  const handleSubmit = async () => {
    try {
      // Validate form
      await validationSchema.validate(form, { abortEarly: false });

      // Proceed to create user
      const response = await createUser({
        domain_name: isAuth?.user?.domain_name,
        firstname: form?.firstname,
        lastname: form?.lastname,
        email_id: form.email_id,
        password: form.password,
        created_by: isAuth?.user?.user_id,
        role_name: form?.role_name,
        role_id: form?.role_id,
        phonecode: form.phonecode,
        mobilenumber: form?.mobile_number,
        reporting_manager: form.reportingManager?.[0]?.h_id || "",
        dottedlinemanager: form.dottedLineManager?.[0]?.value || "",
      });

      if (response) {
        showToast(response.data.status, response.data.status);
        setForm({
          domain_name: "",
          firstname: "",
          lastname: "",
          email_id: "",
          password: "",
          created_by: "",
          role_name: "",
          role_id: "",
          reportingManager: [],
          dottedLineManager: [],
          phonecode: "",
          mobile_number: "",
        });
        setFormErrors({});
      }
    } catch (err) {
      if (err.inner) {
        const errors = {};
        err.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      }
    }
  };

  const {
    data: CountryList,
    isSuccess: CountrySuccess,
    refetch: CountryRefetch,
  } = useGetCountryListQuery();
  const [searchText, setSearchText] = useState("");
  const [searchText2, setsearchText2] = useState("");
  const [SearchedManager, setSearchManager] = useState([]);
  const [SearchDottedLine, setSearchDottedLine] = useState([]);
  const [showManagerList, setShowManagerList] = useState(false);
  const [showDottedList, setShowDottedList] = useState(false);
  const {
    data: AllUser,
    isSuccess: AlluserSuccess,
    refetch: AllUserRefetch,
  } = useGetUsersQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  // console.log("AllUser==>", AllUser);

  useEffect(() => {
    if (searchText.length === 0) {
      setSearchManager([]);
    } else {
      setSearchManager(
        hierachy?.message?.filter((item, i) =>
          item?.label
            ?.toLowerCase()
            ?.replaceAll(" ", "")
            ?.includes(searchText?.toLowerCase().replaceAll(" ", ""))
        )
      );
    }
  }, [searchText]);

  useEffect(() => {
    if (searchText2.length === 0) {
      setSearchDottedLine([]);
    } else {
      setSearchDottedLine(
        AllUser?.message?.filter((item, i) =>
          item?.label
            ?.toLowerCase()
            ?.replaceAll(" ", "")
            ?.includes(searchText2?.toLowerCase().replaceAll(" ", ""))
        )
      );
    }
  }, [searchText2]);

  const [roleData, setroleData] = useState();
  const { data, refetch, isSuccess } = useGetRoleQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  useEffect(() => {
    if (data?.status) {
      setroleData(data?.message || []);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (roleData?.length > 0 && form.role_name) {
      const foundRole = roleData.find(
        (item) => item.role_name === form.role_name
      );
      if (foundRole?.role_id !== form.role_id) {
        // Prevent unnecessary state updates
        setForm((prev) => ({ ...prev, role_id: foundRole?.role_id || "" }));
      }
    }
  }, [roleData, form.role_name]);

  const filterUsers = (users, query) => {
    if (!query) return users;
    return users.filter(
      (user) =>
        user.label?.toLowerCase().includes(query.toLowerCase()) ||
        user.role_name?.toLowerCase().includes(query.toLowerCase())
    );
  };
  var input = document.getElementById('custominput');
  // useEffect(()=>{
  //   input?.addEventListener('keydown', ({key}) => {
  //     if (key === "Backspace" || key === "Delete") {
  //         setForm({...form,reportingManager:""})
  //     }
  // });
  // },[form])
  

 
  return (
    <>
     <h3 className="setting-create-user-heading">Create User</h3>

      <div className="setting-create-user-container">
        {/* search bar */}
        <div className="setting-create-user-search">
          <FaSearch className="setting-createuser-search-icon" />
          <input
            type="text"
            placeholder="Search..."
            className="Setting-createsearch"
            value={searchSettings}
            onChange={(e) => setSearchSettings(e.target.value)}
          />
        </div>
        
        {/* add user button */}
        <div className="setting-create-user-button">
          <button className="ftn" onClick={() => setSettingShowForm(true)}>
            Add User <FaUserEdit className="setting-create-user-icon" />
          </button>

          {SettingShowForm && (
            <div className="Create-User-hidden-form Create-user-show-form">
              <div className="Create-Access-role-modal-header">
                Add User
                <button
                  onClick={() => {
                    setFormErrors({});
                    setSettingShowForm(false);
                  }}
                >
                  ✖
                </button>
              </div>

              <div className="setting-create-user-modal-body">
                {/* First & Last Name */}
                <div className="setting-create-user-row">
                  <input
                    type="text"
                    name="firstname"
                    value={form.firstname}
                    onChange={(e) =>
                      setForm({ ...form, firstname: e.target.value })
                    }
                    placeholder={formErrors.firstname || "First Name"}
                    className={`setting-create-user-input ${
                      formErrors.firstname ? "error-input" : ""
                    }`}
                  />
                  <input
                    type="text"
                    name="lastname"
                    value={form.lastname}
                    onChange={(e) =>
                      setForm({ ...form, lastname: e.target.value })
                    }
                    placeholder={formErrors.lastname || "Last Name"}
                    className={`setting-create-user-input ${
                      formErrors.lastname ? "error-input" : ""
                    }`}
                  />
                </div>

                {/* Role & ID */}
                <div className="setting-create-user-row">
                  <select
                    name="role_name"
                    className={`setting-create-user-input ${
                      formErrors.role_name ? "error-input" : ""
                    }`}
                    value={form.role_name}
                    onChange={(e) =>
                      setForm({ ...form, role_name: e.target.value })
                    }
                  >
                    <option value="">Select a role</option>
                    {roleData?.map((item, i) => (
                      <option key={i} value={item?.role_name}>
                        {item?.role_name}
                      </option>
                    ))}
                  </select>

                  <input
                    type="text"
                    className="setting-create-user-input"
                    placeholder="Enter the Role ID"
                    value={form.role_id}
                    readOnly
                  />
                </div>

                {/* Email & Password */}
                <div className="setting-create-user-row">
                  <div className="setting-create-user-col">
                    <input
                      type="email"
                      name="email_id"
                      value={form.email_id}
                      onChange={(e) =>
                        setForm({ ...form, email_id: e.target.value })
                      }
                      placeholder={"Email"}
                      className={`setting-create-user-input`}
                    />
                    {formErrors.email_id && (
                      <div className="input-error-message">
                        {formErrors.email_id}
                      </div>
                    )}
                  </div>

                  <div className="setting-create-user-col">
                    <input
                      type="password"
                      name="password"
                      value={form.password}
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      placeholder={"Password"}
                      className={`setting-create-user-input `}
                    />
                    {formErrors.password && (
                      <div className="input-error-message">
                        {formErrors.password}
                      </div>
                    )}
                  </div>
                </div>

                {/* Phone & Code */}
                <div className="setting-create-user-row">
                  <div className="setting-create-user-mobile">
                    <select
                      name="phonecode"
                      className={`setting-create-user-code ${
                        formErrors.phonecode ? "error-input" : ""
                      }`}
                      value={form.phonecode}
                      onChange={(e) =>
                        setForm({ ...form, firstname: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      className="setting-create-user-input"
                      placeholder="Enter the LastName"
                      value={form.lastname}
                      onChange={(e) =>
                        setForm({ ...form, lastname: e.target.value })
                      }
                    />
                  </div>
                  </div>
                  {/* 
    <!-- Role & Role ID --> */}
                  <div className="setting-create-user-row">
                    <select
                      className="setting-create-user-input"
                      value={form?.role_name}
                      onChange={(e) =>
                        setForm({ ...form, role_name: e.target.value })
                      }
                    >
                      <option value="">Code</option>
                      {CountryList?.status &&
                        CountryList.message.map((item, i) => (
                          <option key={i} value={item.phonecode}>
                            {item.name} {item.phonecode}
                          </option>
                        ))}
                    </select>
                    <input
                      type="text"
                      name="mobile_number"
                      className={`setting-create-user-input ${
                        formErrors.mobile_number ? "error-input" : ""
                      }`}
                      value={form.mobile_number}
                      onChange={(e) =>
                        setForm({ ...form, mobile_number: e.target.value })
                      }
                      placeholder={
                        formErrors.mobile_number || "Enter the Contact Number"
                      }
                    />
                  </div>

                {/* Reporting Manager */}
                <div className="setting-create-user-row">
                  <input
                    type="text"
                    className="setting-create-user-input"
                    placeholder="Search for Reporting Manager"
                    value={searchText}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchText(value);
                      setShowManagerList(true);
                      if (form.reportingManager.length > 0) {
                        setForm({ ...form, reportingManager: [] }); // Clear selection on typing
                      }
                    }}
                    onFocus={() => {
                      if (form.reportingManager.length === 0) {
                        setShowManagerList(true);
                      }
                    }}
                  />
                </div>

                {showManagerList &&
                  searchText.length > 0 &&
                  SearchedManager?.length > 0 && (
                    <div className="setting-create-user-dropdown">
                      {SearchedManager.filter(
                        (item) =>
                          form.dottedLineManager.length === 0 ||
                          form.dottedLineManager[0]?.value !== item.value
                      ).map((item, i) => (
                        <div
                          className="setting-create-user-dropdown-item"
                          key={i}
                          onClick={() => {
                            if (form.reportingManager.length === 0) {
                              setForm({ ...form, reportingManager: [item] });
                              setSearchText(item.label);
                              setShowManagerList(false);
                            }
                          }}
                        >
                          <button
                            className="setting-create-user-dropdown-cancel"
                            onClick={(e) => {
                              e.stopPropagation();
                              setForm({ ...form, reportingManager: [] });
                              setSearchText("");
                              setShowManagerList(false);
                            }}
                          >
                            ✖
                          </button>
                          <p className="setting-create-user-dropdown-label">
                            {item.label}
                          </p>
                          <p className="setting-create-user-dropdown-value">
                            {item.value}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                {/* Dotted Line Manager */}
                <div className="setting-create-user-row">
                  <input
                    type="text"
                    className="setting-create-user-input"
                    placeholder="Dotted Line Manager (Optional)"
                    value={searchText2}
                    onChange={(e) => {
                      const value = e.target.value;
                      setsearchText2(value);
                      setShowDottedList(true);
                      if (form.dottedLineManager.length > 0) {
                        setForm({ ...form, dottedLineManager: [] }); // Clear selection on typing
                      }
                    }}
                    onFocus={() => {
                      if (form.dottedLineManager.length === 0) {
                        setShowDottedList(true);
                      }
                    }}
                  />
                </div>

                {showDottedList && SearchDottedLine?.length > 0 && (
                  <div className="setting-create-user-dropdown">
                    {SearchDottedLine.filter(
                      (item) =>
                        form.reportingManager.length === 0 ||
                        form.reportingManager[0]?.value !== item.value
                    ).map((item, i) => (
                      <div
                        className="setting-create-user-dropdown-item"
                        key={i}
                        onClick={() => {
                          if (form.dottedLineManager.length === 0) {
                            setForm({ ...form, dottedLineManager: [item] });
                            setsearchText2(item.label);
                            setShowDottedList(false);
                          }
                        }}
                      >
                        <button
                          className="setting-create-user-dropdown-cancel"
                          onClick={(e) => {
                            e.stopPropagation();
                            setForm({ ...form, dottedLineManager: [] });
                            setsearchText2("");
                            setShowDottedList(false);
                          }}
                        >
                          ✖
                        </button>
                        <p className="setting-create-user-dropdown-label">
                          {item.label}
                        </p>
                        <p className="setting-create-user-dropdown-value">
                          {item.value}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="Create-User-Access-role-modal-footer">
                <button
                  className="Create-User-Access-role-cancel-button"
                  onClick={() => setSettingShowForm(false)}
                >
                  Cancel
                </button>
                <button
                  className="Create-User-Access-role-save-button"
                  onClick={handleSubmit}
                >
                  Add User
                </button>
              </div>
            </div>
          )}
        </div>
      </div>




{/* existing users table */}
      <div class="settings-createuser-table-container">
        <div class="settings-createuser-table-wrapper">
          <table class="settings-createuser-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Contact number</th>
                <th>Email</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {filterUsers(AllUser?.message || [], searchSettings).map(
                (item, i) => (
                  <tr key={i}>
                    <td>{item.label || "NA"}</td>
                    <td>{item.role_name || "NA"}</td>
                    <td>{item.mobile_number || "NA"}</td>
                    <td>{item.email_id || "NA"}</td>
                    <td>{item.created_by || "NA"}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
