import React, { useState } from "react";
import "./WinspireHeader.css";
import { FaBell, FaEnvelope, FaUserCircle } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/API/authSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";
import plus from "../../Assests/plus.svg";
import { Plus } from "lucide-react";
import User2 from "../../Assests/user.png";
import {
  useGetCustomerMenuItemQuery,
  useGetUserProfileQuery,
} from "../../Redux/API/atsSlice";
import { useGetSettingsQuery } from "../../Redux/API/atsSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import usePrivilege from "../Privileges/Privileges";

const WinspireHeader = () => {
  const [menuEnable, setMenuEnable] = useState([]);
  const dispatch = useDispatch();
  // const user_id = isAuth?.user?.user_id;
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [addNew, setAddNew] = useState(false);
  const [buttonName, setButtonName] = useState([]);
  const [editWidget, setEditWidget] = useState(false);
  const privilege = usePrivilege();

  const isAuth = useSelector((state) => state.auth);

  const { data, isSuccess } = useGetCustomerMenuItemQuery({
    domain_name: isAuth?.user.domain_name,
  });
  useEffect(() => {
    if (isSuccess && data?.message) {
      const mainHeaderSec = data.message.find(
        (sec) => sec.header_name === "main_header"
      );
  
      if (mainHeaderSec?.items?.length) {
        const addNewMenu = mainHeaderSec.items.find(
          (item) => item.name_of_the_menu === "Add New"
        );
  
        if (addNewMenu) {
          setButtonName([{
            name: addNewMenu.name_of_the_menu,
            icon: addNewMenu.icon,
            children: addNewMenu.child || [],
          }]);
        }
      }
    }
  }, [isSuccess, data]);
  
  const jobCreate = usePrivilege("Job Create");

  const deptCreate = usePrivilege("Department Create");
  const candidatecreate = usePrivilege("Candidate Create");
  const interviewcreate = usePrivilege("Interview Create");

  const main_header =
    data?.message?.find((section) => section.header_name === "main_header")
      ?.items || [];

  console.log("main-header",main_header);
  
  const handleLogOut = (e) => {
    e.preventDefault();

    navigate("/");
    setTimeout(() => {
      dispatch(logout());
    }, 1000);
  };

  const { data: profiledata, refetch: Profilerefetch } = useGetUserProfileQuery(
    { user_id: isAuth?.user?.user_id }
  );

  console.log("pro", profiledata);
  useEffect(() => {
    Profilerefetch();
  }, []);
  console.log("profile==>", profiledata);
  return (
    <div className="winspire-header">
      {/* Left Section - User Profile */}
      <div className="winspire-header-left">
        {main_header
        
          .filter((item) => item.type_of_the_menu === "customer_logo")
          .map((logoItem) => (
            <a
              key={logoItem.id}
              href={logoItem.route_name || "#"}
              className="logo-link"
            >
              <img
                src={`${BASE_URL}${logoItem.name_of_the_menu}`}
                alt="Customer Logo"
                className="winspire-logo"
              />
            </a>
          ))}
      </div>
      <div className="winspire-header-right">
        {main_header
  .filter((item) => item.type_of_the_menu === "customer_menu_icon")
  .map((iconItem) => (
    <div key={iconItem.id} className="header-icon-wrapper">
      {/* "Add New" should open dropdown */}
      {iconItem.name_of_the_menu === "Add New" ? (
        // <div className="ats-subheader-dropdown" style={{backgroundColor:'palegoldenrod'}}>
        <div className="ats-subheader-dropdown" style={{width:40,translate:'0 -2px 0'}}>
          <a className="ats-subheader-btn" onClick={() => setAddNew(!addNew)}>
            <img
              src={`${BASE_URL}${iconItem.icon}`}
              alt={iconItem.name_of_the_menu}
              className="header-icon"
            />
          </a>
          {addNew && (
            <ul className="ats-subheader-dropdown-menu">
              {iconItem.child?.length > 0 ? (
                iconItem.child
                  .filter((child) => {
                    const permissions = {
                      Job: jobCreate,
                      Departments: deptCreate,
                      Candidate: candidatecreate,
                      Interview: interviewcreate,
                    };
                    return permissions[child.name_of_the_menu]?.includes(
                      isAuth.user?.user_id
                    );
                  })
                  .map((child) => (
                    <li key={child.id}>
                      <a href={child.route_name}>{child.name_of_the_menu}</a>
                    </li>
                  ))
              ) : (
                <li>No Access</li>
              )}
            </ul>
          )}
          </div>
        // </div>
      ) : (
        // All other icons
        <a
          href={iconItem.route_name ? iconItem.route_name : "#"}
          className="header-icon-link"
        >
          <img
            src={`${BASE_URL}${iconItem.icon}`}
            alt={iconItem.name_of_the_menu}
            className="header-icon"
            style={
              iconItem.name_of_the_menu === "Settings"
                ? { filter: "brightness(0) invert(1)" }
                : iconItem.name_of_the_menu === "Mail" &&
                  parseInt(menuEnable?.is_mail) === 0
                ? { display: "none" }
                : iconItem.name_of_the_menu === "Notification" &&
                  parseInt(menuEnable?.is_notification) === 0
                ? { display: "none" }
                : {}
            }
          />
        </a>
      )}
    </div>
))}


        {isAuth?.isAuthenticated ? (
          <div className="user-info">
            <div
              className="profile-icon"
              onClick={() => setShowMenu(!showMenu)}
            >
              {profiledata?.message?.profile_pic ? (
                <img
                  src={
                    profiledata.message.profile_pic.startsWith("public")
                      ? `${BASE_URL}${profiledata.message.profile_pic}`
                      : profiledata.message.profile_pic
                  }
                  alt="Profile"
                  className="winspireheader-profile-icon-img"
                />
              ) : (
                <div className="profile-placeholder">
                  {profiledata?.message?.first_name?.[0]?.toUpperCase() || "U"}
                </div>
              )}
              <span className="dropdown-arrow">
                <FaCaretDown className="profile-dropdown-arrow" />
              </span>
            </div>

            {showMenu && (
              <div className="user-menu">
                <div className="user-profile-box">
                  <div className="user-avatar">
                    {profiledata?.message?.profile_pic?.startsWith("public") ? (
                      <img
                        src={`${BASE_URL}${profiledata.message.profile_pic}`}
                        alt="Profile"
                        className="user-avatar-img"
                      />
                    ) : profiledata?.message?.profile_pic ? (
                      <img
                        src={profiledata.message.profile_pic}
                        alt="Profile"
                        className="user-avatar-img"
                      />
                    ) : (
                      <span>
                        {profiledata?.message?.first_name?.[0]?.toUpperCase() ||
                          "U"}
                      </span>
                    )}
                  </div>
                  <div className="user-name">
                    {profiledata?.message?.first_name}{" "}
                    {profiledata?.message?.last_name?.[0]}
                  </div>
                </div>

                <button className="logout-btn" onClick={(e) => handleLogOut(e)}>
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <span className="welcome-text">Welcome, Guest</span>
        )}
      </div>
    </div>
  );
};

export default WinspireHeader;
