import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteInterviewMutation, useGetcustomerInterviewsQuery, useGetInterviewByIdQuery } from '../../Redux/API/atsSlice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import usePrivilege from '../Privileges/Privileges';
import CommonTable from '../CommonTable/CommonTable';
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CiSquarePlus } from "react-icons/ci";
import { FaTimes } from "react-icons/fa";
import "./Interview.css"
import EmptyData from '../EmptyData/EmptyData';
import renderHTML from "react-render-html";
import { Link } from 'react-router-dom';
import CreateInterview from '../CreateInterview/CreateInterview';
import EditInterview from './EditInterview';
const Interview = ({ isCreate = false, isEdit = false }) => {
  const [interviewData, setInterviewData] = useState([]);
  const [detailInterviewData, setDetailInterviewData] = useState([]);
  const [showInterview, setShowInterview] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false);
  const isAuth = useSelector((state) => state.auth);
  const [selectedInterview, setSelectedInterview] = useState("");
  const location = useLocation()
  const [params] = useSearchParams()

  const navigate = useNavigate()

  const { data, isSuccess, refetch } = useGetcustomerInterviewsQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id: isAuth?.user?.role_id,
    user_id: isAuth?.user?.user_id
  });


  const isInterviewUpdate = usePrivilege('Interview Update')
  useEffect(() => {
    refetch();
  }, [refetch]);


  function getCompletedInterviews(interviews) {
    const now = new Date();

    const isValidDate = (dateStr) => {
      const d = new Date(dateStr);
      return !isNaN(d.getTime());
    };

    return interviews
      ?.filter(item => isValidDate(item.interview_date))
      ?.filter(item => new Date(item.interview_date) < now)
      ?.sort((a, b) => new Date(a.interview_date) - new Date(b.interview_date));
  }


  function getInterviewsThisWeek(interviews) {
    const today = new Date();
    const day = today.getDay();
    const diffToMonday = (day === 0 ? -6 : 1 - day);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() + diffToMonday);
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const isValidDate = (date) => {
      const d = new Date(date);
      return !isNaN(d.getTime());
    };

    return interviews
      ?.filter(item => isValidDate(item.interview_date))
      ?.filter(item => {
        const date = new Date(item.interview_date);
        return date >= startOfWeek && date <= endOfWeek;
      })
      ?.sort((a, b) => new Date(a.interview_date) - new Date(b.interview_date));
  }

  useEffect(() => {
    const parseInterviewers = (interviews) => {
      return (interviews || []).map(item => {
        let interviewerValue = item.interviewer;
        try {
          const parsed = JSON.parse(item.interviewer);
          if (Array.isArray(parsed)) {
            interviewerValue = parsed.map(i => i.label).join(", ");
          }
        } catch (e) {

        }
        return {
          ...item,
          interviewer: interviewerValue
        };
      });
    };

    if (isSuccess) {
      let rawData = data?.message || [];


      const parsedData = parseInterviewers(rawData);


      if (params.get('filter') === 'this-week') {
        setInterviewData(getInterviewsThisWeek(parsedData));
      } else if (params.get("filter") === "completed-interviews") {
        setInterviewData(getCompletedInterviews(parsedData));
      } else if (location?.state) {
        setInterviewData(parseInterviewers(location?.state?.value));
      } else {
        setInterviewData(parsedData);
      }
    }
  }, [isSuccess, data, location, params]);



  const columns = [
    { key: "interview_id", label: "Interview ID" },
    { key: "interview_for", label: "Interview For" },
    { key: "interview_date", label: "Interview Date" },
    { key: "interview_stage", label: "Interview Stage" },
    { key: "interviewer", label: "Interviewer" },
    { key: "location", label: "Location" },
    { key: "created_at", label: "Created At" },


  ];



  console.log("......", interviewData)
  const [deleteInterview] = useDeleteInterviewMutation();
  const handleDelete = async (e, interview_id) => {
    e.preventDefault()
    const obj = {
      id: interview_id
    }
    try {
      const res = await deleteInterview({
        domain_name: isAuth?.user?.domain_name,
        data: obj
      })
      setShowConfirm(false)
      if (res?.data?.status) {
        refetch()
      }
      console.log("res", res)
    } catch (err) {

    }
  }





  const { data: interviewDetail, isSuccess: interviewDetailSuccess } = useGetInterviewByIdQuery({
    domain_name: isAuth?.user?.domain_name,
    interview_id: selectedInterview
  });

  useEffect(() => {
    if (selectedInterview?.length === 0) {
      setShowConfirm(false)
    }
  }, [])
  useEffect(() => {
    if (interviewDetail?.status && interviewDetail?.message?.length > 0) {
      setDetailInterviewData(interviewDetail?.message)
      setShowInterview(true)
    }

  }, [interviewDetail, interviewDetailSuccess])
  console.log("selected interview", interviewDetail)
  console.log("detailInterview", detailInterviewData)


  const handleRowAction = (interviewData) => (


    <>

      <button
        onClick={() => navigate(`/ats/dashboard/EditInterview/${interviewData.interview_id}`)}
        className="commonTable-dropdown-item"
      >
        <FaRegEdit /> Edit Interview
      </button>
      <button
        className="commonTable-dropdown-item"
        onClick={() => setShowConfirm(true)}>
        <MdOutlineDeleteOutline /> Delete Interview
      </button>

      {showConfirm && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Are you sure you want to delete this job?</p>
            <div className="popup-actions">
              <button onClick={(e) => handleDelete(e, interviewData?.interview_id)}>
                proceed
              </button>
              <button onClick={() => setShowConfirm(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

    </>
  );

  const sidebarLabels = [
    "All Interviews",
    "Active Interviews",
    "Inactive Interviews",
    "Recent Interviews",
    "My Interviews"
  ];

  const [selectedFilter, setSelectedFilter] = useState("All Interviews");
  const [sortOrder, setSortOrder] = useState("Newest First");
  const [selectedColumns, setSelectedColumns] = useState([
    "interview_id", "interview_for", "interview_date", "interview_stage", "interviewer", "location", "created_at"
  ]);

  const getFilteredInterviewData = () => {
    let filteredInterviewData = [...interviewData];

    if (selectedFilter === "Active Candidates") {
      filteredInterviewData = interviewData.filter(candidate => candidate.status === "Active");
    } else if (selectedFilter === "Inactive Candidates") {
      filteredInterviewData = interviewData.filter(candidate => candidate.status === "Inactive");
    } else if (selectedFilter === "Recent Candidates") {
      filteredInterviewData = interviewData.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    const getParsedDate = (dateString) => {
      if (!dateString) return new Date(0);
      return new Date(dateString.replace("at", ""));
    };

    if (sortOrder === "Newest First") {
      filteredInterviewData.sort((a, b) => getParsedDate(b.created_at) - getParsedDate(a.created_at));
    } else if (sortOrder === "Oldest First") {
      filteredInterviewData.sort((a, b) => getParsedDate(a.created_at) - getParsedDate(b.created_at));
    }

    return filteredInterviewData;
  };
  console.log('Selected', selectedInterview)


  console.log("detail intervwwww", detailInterviewData)
  return (
    <div>
      <CommonTable
        setSelectInterview={setSelectedInterview}
        module_name={"interviews"}
        headerButton="Schedule Interview"
        headerIcon={<CiSquarePlus />}
        headerNavigateTo="/ats/dashboard/?type=createInterviews"
        columns={columns}
        // data={interviewData}
        meta_title={"Interviews"}
        data={getFilteredInterviewData()}
        onRowAction={handleRowAction}
        title={selectedFilter}
        sidebarLabels={sidebarLabels}
        rowIdentifier='interview_id'
        privilege={"Interview Create"}
        onFilterChange={setSelectedFilter}
        onSortChange={setSortOrder}
        selectedColumns={selectedColumns}
        onColumnChange={setSelectedColumns}
        showInsideSearchBar={true}
   
        showSearchBarContainer={false}
        showInsideHeaderButton={true}

      />


      {isCreate && (
        <div className="interview-detail-popup-overlay">
          <div className="interview-detail-popup open">
            <div className="interview-detail-popup-header">
              <h3>Create Interview</h3>
              <button
                className="interview-detail-popup-close-btn"
                onClick={() => {
                  isCreate = false
                  navigate('/ats/dashboard/?type=interviews')
                }}
              >
                <FaTimes />
              </button>
            </div>
            <div className="interview-detail-popup-content" style={{paddingBottom:'0'}}>
              <CreateInterview />
            </div>

            {/* <div className="interview-detail-popup-fixedcontainer">
              <div className="interview-detail-popup-line"></div>
              <div className="interview-detail-popup-bottomCon">
                <button
                  className="interview-detail-popup-cancelbtn"
                  onClick={() => {
                    isCreate = false
                    navigate('/ats/dashboard/?type=interviews')
                  }}
                >
                  Cancel
                </button>
                <button
                  className="interview-detail-popup-savebtn"
                  // onClick={() =>
                  //   navigate(`/ats/dashboard/EditInterview/${selectedInterview}`)
                  // }
                  onClick={() => navigate(`/ats/dashboard/EditInterview/${selectedInterview}`)}
                >
                  Edit
                </button>
              </div>
            </div> */}
          </div>
        </div>
      )}


      {isEdit && (
        <div className="interview-detail-popup-overlay">
          <div className="interview-detail-popup open">
            <div className="interview-detail-popup-header">
              <h3>Edit Interview</h3>
              <button
                className="interview-detail-popup-close-btn"
                onClick={() => {
                  isEdit = false
                  navigate('/ats/dashboard/?type=interviews')
                }}
              >
                <FaTimes />
              </button>
            </div>
            <div className="interview-detail-popup-content" style={{paddingBottom:'0'}}>
              <EditInterview />
            </div>

            {/* <div className="interview-detail-popup-fixedcontainer">
              <div className="interview-detail-popup-line"></div>
              <div className="interview-detail-popup-bottomCon">
                <button
                  className="interview-detail-popup-cancelbtn"
                  onClick={() => {
                    isEdit = false
                    navigate('/ats/dashboard/?type=interviews')
                  }}
                >
                  Cancel
                </button>
                <button
                  className="interview-detail-popup-savebtn"
                  // onClick={() =>
                  //   navigate(`/ats/dashboard/EditInterview/${selectedInterview}`)
                  // }
                  onClick={() => navigate(`/ats/dashboard/EditInterview/${selectedInterview}`)}
                >
                  Edit
                </button>
              </div>
            </div> */}
          </div>
        </div>
      )}


      {showInterview && (
        <div className="interview-detail-popup-overlay">
          <div className="interview-detail-popup open">
            <div className="interview-detail-popup-header">
              <h3>Interview Detail</h3>
              <button
                className="interview-detail-popup-close-btn"
                onClick={() => setShowInterview(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="interview-detail-popup-content">
              {Array.isArray(detailInterviewData) && detailInterviewData.length > 0 ? (
                detailInterviewData.map((section, index) => (
                  <div key={index} className="interview-section">
                    <div className="interview-grid">
                      {Array.isArray(section.values) &&
                        section.values.map((item, idx) => {
                          const label = Object.keys(item).find(
                            (key) => key !== "typeOftheField" && key !== "index_of"
                          );
                          const value = label ? item[label] : "";

                          return (
                            <div key={idx} className="interview-row">
                              <div className="interview-label">{label}</div>
                              <div className="interview-value">
                                {
                                  label === "Interview Date" || label === "Created At" ? (
                                    new Date(value).toLocaleString()
                                  ) : label === "Interviewer" ? (
                                    JSON.parse(value).map((person) => person.label).join(", ")
                                  ) : label === "Note" ? (
                                    <p>{renderHTML(value)}</p>
                                  ) : label === "Link" ? (
                                    <Link to={value}><p className='interview-detail-link'>{value}</p></Link>
                                  ) : (
                                    value
                                  )
                                }
                              </div>

                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))
              ) : (
                // <p style={{ padding: "10px", color: "#888", textAlign: "center" }}>
                //   No interview content is available.
                // </p>
                <div>
                  <EmptyData />
                </div>

              )}
            </div>

            <div className="interview-detail-popup-fixedcontainer">
              <div className="interview-detail-popup-line"></div>
              <div className="interview-detail-popup-bottomCon">
                <button
                  className="interview-detail-popup-cancelbtn"
                  onClick={() => setShowInterview(false)}
                >
                  Cancel
                </button>
                <button
                  className="interview-detail-popup-savebtn"
                  // onClick={() =>
                  //   navigate(`/ats/dashboard/EditInterview/${selectedInterview}`)
                  // }
                  onClick={() => navigate(`/ats/dashboard/EditInterview/${selectedInterview}`)}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Interview;
